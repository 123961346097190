import React from "react"

// Styles
import styles from "../styles/components/title.module.scss"

export default function title({ text, subtitle }) {
  return (
    <div className={styles.main}>
      <h1 className={styles.title}>{text}</h1>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  )
}
