import React from "react"
import ReactPlayer from "react-player"

//components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Title from "../components/title"

//styles
import styles from "../styles/pages/videos.module.scss"
import { Col, Row, Card, Button } from "react-bootstrap"
import Header from "../components/navigation/header"
import Footer from "../components/footer"

const videoFiles = [
  {
    url: "https://www.youtube.com/watch?v=vr-VIPONqr8",
    title: "Sunterra",
    desc: (
      <div className="small">
        <p className="mb-0">Our mission is to provide the highest efficiency</p>
        and lowest cost per watt of any solar provider
        <p className="mb-0"> within the country</p>
      </div>
    ),
  },
  {
    url: "https://youtu.be/cUqtx69o8Zo",
    title: "Solar installation - Kuliyapitiya 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Kuliyapitiya</p>
        <p className="mb-0">PV Modules - 450W x 66</p>
        <p className="mb-0">System size - 30kW</p>
      </div>
    ),
  },
  {
    url: "https://www.youtube.com/watch?v=MuzD-XUpVA0",
    title: "Solar installation - Maharagama 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Maharagama 2021</p>
        <p className="mb-0">PV Modules - 450W x 50</p>
        <p className="mb-0">System size - 22kW</p>
      </div>
    ),
  },
  {
    url: "https://www.youtube.com/watch?v=f3Agmaib9t0&t=1s",
    title: "Solar installation - Maharagama 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Maharagama 2021</p>
        <p className="mb-0">PV Modules - 450W x 50</p>
        <p className="mb-0">System size - 22kW</p>
      </div>
    ),
  },
  {
    url: "https://www.youtube.com/watch?v=snWwOSZLZ9s&t=4s",
    title: "Solar installation - Colombo 09 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Colombo 09 2021</p>
        <p className="mb-0">PV Modules - 320W x 132</p>
        <p className="mb-0">System size - 40kW</p>
      </div>
    ),
  },
  {
    url: "https://www.youtube.com/watch?v=3xCXM5I_4nI",
    title: "Solar installation - Moratuwa 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Moratuwa 2021</p>
        <p className="mb-0">PV Modules - 450W x 26</p>
        <p className="mb-0">System size - 12kW</p>
      </div>
    ),
  },
  {
    url: "https://www.youtube.com/watch?v=cUqtx69o8Zo&t=13s",
    title: "Solar installation - Kuliyapitiya 2021",
    desc: (
      <div className="small">
        <p className="mb-0">Solar installation - Kuliyapitiya 2021</p>
        <p className="mb-0">PV Modules - 450W x 66</p>
        <p className="mb-0">System size - 30kW</p>
      </div>
    ),
  },
]

export default function Videos() {
  return (
    <>
      <Header />
      <SEO
        title="Videos"
        article={false}
        pathname="/videos"
        description="Our solar systems are sleek and durable — silently converting sunlight to electricity for decades to come."
      />
      <Layout>
        <Title text="Videos" subtitle="" />
        <Row className="gy-5">
          {videoFiles.map((file, i) => (
            <Col sm={12} md={6} lg={4} key={i}>
              <Card className="align-items-center pt-3">
                <ReactPlayer url={file.url} className={styles.video} />
                <Card.Body className="my-3">
                  <h3 className="fw-bold">{file.title}</h3>

                  {file.desc}
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    <Button className="mt-4">Watch</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Layout>
      <Footer />
    </>
  )
}
